import { createStore } from "vuex"
import createPersistedState from 'vuex-persistedstate'


const store = createStore({
    state: {
        isLogged: false,
        token: '',
        token_type: '',
        usuario: null,
        pageHeader: {},
        expires_at: '',
        isAdmin: false,
        isOperator: false,
        isGestor: false,
        isProgramador: false
    },

    getters: {
        checkLogged: state => {
            return state.isLogged
        },

        getToken: state => {
            return `${state.token}`
        },

        getUsuario: state => {
            return state.usuario
        },

        isAdmin: state => {
            return state.isAdmin
        },

        isGestor: state => {
            return state.isGestor
        },

        isOperator: state => {
            return state.isOperator
        },

        isProgramador: state =>{
            return state.isProgramador
        }
    },

    mutations: {
        SET_LOGGED(state, payload) {
            state.isLogged = payload.isLogged,
            state.token = payload.token,
            state.token_type = payload.token_type
            state.expires_at = payload.expires_at

        },
        SET_USER(state, payload) {
            state.usuario = payload.usuario

            state.isAdmin = state.usuario.role === "ADMIN" ? true : false
            state.isOperator = state.usuario.role === "OPERATOR" ? true : false
            state.isGestor = state.usuario.role === "REGION_MANAGER" ? true : false
            state.isProgramador = state.usuario.role === "PROGRAMMER" ? true : false
        },
        updateStoredRoutes(state, payload){
            state.pageHeader.currentRoute = payload.currentRoute
            state.pageHeader.previousRoute = payload.previousRoute
        }
    },

    actions: {
        setLogged(context, payload) {
            context.commit('SET_LOGGED', payload)
        },

        setUser(context, payload) {
            context.commit('SET_USER', payload)
        }
    },

    plugins: [createPersistedState()],

})

export default store