import services from '../services'
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter,useRoute } from 'vue-router'

export default function () {

    
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
        usuarios: [],
        allUsuarios: [],

        usuarioLogado: computed(()=>store.getters.getUsuario),

        nomeUsuario: '',

        timer: null,

        loading:{
            criarUsuario: false,
            editarUsuario: false,
            carregarUsuarios: false,
            alterarSenha: false
        }
    })

    
    //remove o usuario logado da tabela
    const usuariosTabela = computed(() => {
        return state.usuarios.filter((usuario) => usuario.id !== state.usuarioLogado.id)
    })

    
    async function carregarUsuarios(params) {
        state.loading.carregarUsuarios = true

        let rota = route.name
        const token = store.getters.getToken
        
        try {
            const response = await services.users.getAll({ token, rota, params })
            state.usuarios = response.data.data
            state.allUsuarios = state.usuarios
        }
        catch (e) {
            if (e.response.data.status == 403 && e.response.data.message == 'Token JWT expirado') {
                router.push({ name: 'login' })
            }
        }
        finally{
            state.loading.carregarUsuarios = false
        }
        
    }


    function pesquisar() {
        if (state.timer) {
            clearTimeout(state.timer)
        }

        state.timer = setTimeout(() => {
            state.usuarios = state.allUsuarios.filter(usuario => {
                const values = Object.values(usuario)
                return values.some(v => String(v).toLowerCase().includes(state.nomeUsuario.toLowerCase()))
            })
        }, 700)
    }


    
    return {
        stateUsers: state,
        usuariosTabela,
        carregarUsuarios,
        pesquisar,
    }
}